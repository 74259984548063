import React from "react"
import { Link } from "gatsby"
import styled from "@emotion/styled"
import { css } from "@emotion/core"
import { Helmet } from "react-helmet"

import SEO from "../components/seo"
import SubscribeToEmail from "../components/subscribe-to-email"
import Footer from "../components/footer"

import "../components/reset.css"
import logoImg from "../images/noah-logo-borderless.svg"

const Wrapper = styled.div`
  font-family: Muli;
  max-width: 530px;
  padding: 0 15px;
  margin: 0 auto;
  color: #222;
`

const H = styled.header`
  margin-bottom: 1em;
  color: #888;
  font-weight: bold;
  border-top: 1px solid #eee;
  padding-top: 10px;
`

const Section = styled.section`
  margin: 60px 0 70px;
`

// the min-width here is a hack to make some space between columns
const TCell = styled.td`
  padding: 0;
  min-width: 65px;
`

const linkStyle = css`
  text-decoration: none;
  color: #5d27ff;
`

const IndexPage = ({ data }) => {
  return (
    <Wrapper>
      <SEO title="Home" />
      <Helmet>
        <link
          href="https://fonts.googleapis.com/css?family=Lora:400,400i|Muli"
          rel="stylesheet"
        />
      </Helmet>
      <div
        css={css`
          text-align: center;
          margin: 12% auto 40px;
        `}
      >
        <a href="/">
          <img
            alt="logo"
            src={logoImg}
            css={css`
              @media (min-width: 630px) {
                width: 100%;
              }
              @media (max-width: 629px) {
                width: 95%;
              }
            `}
          />
        </a>
      </div>
      <p
        css={css`
          margin-bottom: 2.5em;
          line-height: 1.5em;
          hyphens: auto;
        `}
      >
        Hi! I’m Noah Tye. This is my personal website.
      </p>
      <Section>
        <H>Writing</H>
        {data.allMarkdownRemark.nodes.map(node => (
          <div
            css={css`
              font-family: Muli;
            `}
            key={node.fields.slug}
          >
            <div
              css={css`
                float: right;
                color: #888;
              `}
            >
              {node.frontmatter.date}
            </div>
            <div>
              <Link to={node.fields.slug} css={linkStyle}>
                {node.frontmatter.title}
              </Link>
            </div>
          </div>
        ))}
      </Section>
      <Section>
        <H>Projects</H>
        <div>
          <a css={linkStyle} href="https://coterm.dev/">
            Coterm
          </a>
          : a shareable terminal with great autocomplete
        </div>
        <div>
          <a css={linkStyle} href="https://github.com/noahlt/parse-clear">
            ParseClear
          </a>
          : easy, readable parsers in Javascript
        </div>
      </Section>
      <Section>
        <H>Misc</H>
        <div>
          <Link to="/quotes" css={linkStyle}>
            Quotes
          </Link>
          : my collection
        </div>
        <div>
          <Link to="/relationship-space" css={linkStyle}>
            Relationship space
          </Link>
          : relationships are not a bank balance
        </div>
      </Section>
      <Section>
        <H>Elsewhere</H>
        <table>
          <tbody>
            <tr>
              <TCell>email</TCell>
              <TCell>hi@noahtye.com</TCell>
            </tr>
            <tr>
              <TCell>twitter</TCell>
              <TCell>
                <a css={linkStyle} href="http://twitter.com/noahlt">
                  @noahlt
                </a>
              </TCell>
            </tr>
            <tr>
              <TCell>github</TCell>
              <TCell>
                <a css={linkStyle} href="http://github.com/noahlt">
                  noahlt
                </a>
              </TCell>
            </tr>
          </tbody>
        </table>
      </Section>

      <SubscribeToEmail>
        Want to know when I make something new?
      </SubscribeToEmail>

      <Footer />
    </Wrapper>
  )
}

export default IndexPage

export const query = graphql`
  query {
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      nodes {
        id
        fields {
          slug
        }
        frontmatter {
          title
          date(formatString: "MMM YYYY")
        }
      }
    }
  }
`
